import { IconTable } from '@components/table/IconTable';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
// import { useFetchBuildingTopChanges } from 'api/changes/changesServices';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { CardContainer } from 'layout/Card';
import { type FC, useState } from 'react';
import useGetMostImportantChangesTableColumns from './useGetMostImportantChangesTableColumns';

interface MostImportantChangesTableProps {
  buildingId?: string;
}

const MostImportantChangesTable: FC<MostImportantChangesTableProps> = ({
  buildingId,
}) => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { theme } = useTheme();
  // const { data, isFetching } = useFetchBuildingTopChanges(buildingId);
  const { columns } = useGetMostImportantChangesTableColumns();

  return (
    <CardContainer>
      <div className="flex items-center gap-4 py-2">
        <h3 className="font-bold text-base">Most important</h3>
        <TooltipIconComponent content="TBD" />
      </div>
      <IconTable
        minHeight={200}
        skeletonAmountWhenLoading={3}
        isFetching={false}
        enableRowBorder={false}
        stripped={false}
        onRowHover={(id) => {
          setHoveredId(id);
        }}
        hoveredId={hoveredId}
        hoverColor={theme.colors['tertiary-lighter']}
        enableRowRoundedBorder={true}
        data={[
          {
            id: 1,
            utility_type: 'ELECTRICITY',
            meter_name: 'Meter 1',
            moment_change: '2024-01-16 06:00:00',
            impact_average_diff_euro: 500,
          },
        ]}
        columns={columns}
      />
    </CardContainer>
  );
};
export default MostImportantChangesTable;
