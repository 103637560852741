import HChart from '@components/HChart/HChart';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import SankeyModule from 'highcharts/modules/sankey';
// import { useFetchBuildingSankey } from 'api/assets/buildingViewService';
import type Highcharts from 'highcharts';
import { type SankeyHidden } from 'interfaces/assets/Building.interface';
import sankeyMock from '../../mock/sankeyMock.json';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineRemove } from 'react-icons/md';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type TooltipFormatterContextObject } from 'highcharts';

interface BuildingSankeyChartProps {
  id?: string;
}
interface SankeyPoint {
  fromNode?: {
    name: string;
  };
  toNode?: {
    name: string;
  };
  name?: string;
  weight?: number;
  sum?: number;
}

const getNodeColor = (color: string): string => {
  return color === 'green' ? 'rgba(140, 209, 52, 1)' : 'rgba(233, 34, 82, 1)';
};

const BuildingSankeyChart: FC<BuildingSankeyChartProps> = ({ id }) => {
  const { theme } = useTheme();
  const [expandedNode, setExpandedNode] = useState<string | undefined>(
    undefined
  );
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const [nodePositions, setNodePositions] = useState<
    Record<string, { x: number; y: number }>
  >({});
  // const { data } = useFetchBuildingSankey(id);
  const data = sankeyMock;

  const hiddenConnections = useMemo<SankeyHidden>(() => {
    if (data) return data?.asset_sankey?.hidden;
    return {};
  }, [data]);

  const visibleData = useMemo(() => {
    const sankeyData = data?.asset_sankey;
    const visibleData =
      sankeyData?.links.map((mock) => {
        return {
          from: mock.source,
          to: mock.target,
          weight: mock.value,
        };
      }) ?? [];
    if (expandedNode && hiddenConnections?.[expandedNode]) {
      const filteredData = visibleData.filter(
        (node) => node.from !== expandedNode && node.to !== null
      );
      const filteredHiddenConnections = hiddenConnections[expandedNode].map(
        ({ color, ...rest }) => rest
      );

      return [...filteredData, ...filteredHiddenConnections];
    }

    return visibleData;
  }, [data, hiddenConnections, expandedNode]);

  const updateChartData = useCallback(() => {
    if (chart) {
      chart.series[0].setData(visibleData);
    }
  }, [data, visibleData]);

  const handleExpandNode = (nodeId: string): void => {
    if (expandedNode === nodeId) {
      setExpandedNode(undefined);
    } else {
      setExpandedNode(nodeId);
    }
    updateChartData();
  };

  const chartNodes = useMemo(() => {
    const fall = data?.asset_sankey;
    if (fall?.nodes) {
      const nodes = fall.nodes.map((node) => ({
        ...node,
        color: getNodeColor(node.color),
      }));

      if (expandedNode && hiddenConnections?.[expandedNode]) {
        const newExpandedNodes = hiddenConnections[expandedNode];
        newExpandedNodes.forEach((newNode) => {
          nodes.push({
            color: getNodeColor(newNode.color),
            id: newNode.to,
            name: newNode.to,
          });
        });
      }
      return nodes;
    }
    return [];
  }, [data, hiddenConnections, expandedNode]);

  const options: Highcharts.Options = {
    chart: {
      type: 'sankey',
      spacingTop: 25,
      spacingBottom: 25,
      spacingLeft: 25,
      spacingRight: 25,
      events: {
        render(this: Highcharts.Chart) {
          setChart(this);
        },
        redraw(this: Highcharts.Chart) {
          setChart(this);
        },
      },
    },
    xAxis: {
      scrollbar: {
        enabled: false,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      sankey: {
        linkColorMode: 'to',
        nodeWidth: 30,
        // nodePadding: 40,
        nodeAlignment: 'center',
        nodePadding: 30,
        // nodeDistance: 16,
      },
    },
    tooltip: {
      backgroundColor: theme.colors['gray-25'], // Change tooltip background color
      useHTML: true,
      formatter(this: TooltipFormatterContextObject) {
        const point = this.point as unknown as SankeyPoint;

        return point?.fromNode && point?.toNode && point?.weight && point
          ? `<b>${point.fromNode.name}</b> → <b>${point.toNode.name}</b>: <br>${point.weight} kWh`
          : `<b>${point?.name ?? ''}</b>: <br>${point?.sum ?? 0} kWh`;
      },
    },

    series: [
      {
        keys: ['from', 'to', 'weight'],
        data: visibleData,
        type: 'sankey',
        name: 'Flow',
        linkOpacity: 0.25,
        curveFactor: 0.4,
        nodes: chartNodes,
      },
    ],
  };

  useEffect(() => {
    if (!chart) {
      return;
    }

    const updatePositions = (): void => {
      const positions: Record<string, { x: number; y: number }> = {};
      const series = chart?.series?.[0];
      if (!series) return;

      (series as any).nodes.forEach((node: any) => {
        if (!hiddenConnections[node.id]) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const x = node.nodeX + chart.plotLeft;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const y = node.nodeY + chart.plotTop + node.shapeArgs.height / 2;

        positions[node.id] = { x, y };
      });

      setNodePositions(positions);
    };
    setTimeout(updatePositions, 0);

    (chart as any)?.addEvent?.('redraw', updatePositions);
    return () => {
      if (chart) {
        (chart as any)?.removeEvent?.('redraw', updatePositions);
      }
    };
  }, [chart, hiddenConnections, expandedNode]);

  return (
    <div className="relative w-full h-96" id="building-sankey">
      <HChart options={options} modules={[SankeyModule]} callback={setChart} />
      {Object.entries(nodePositions).map(([nodeId, position]) => (
        <button
          key={nodeId}
          className="absolute transform -translate-y-1/2 rounded-full p-1"
          style={{
            left: `${position.x + 35}px`,
            top: `${position.y}px`,
            zIndex: 1000,
          }}
          onClick={() => {
            handleExpandNode(nodeId);
          }}
          title={`${expandedNode === nodeId ? 'Collapse' : 'Expand'} ${nodeId}`}
        >
          <div
            className={`w-[20px] h-[20px] leading-none border flex items-center justify-center rounded-full bg-white shadow-lg transition-transform`}
          >
            {expandedNode === nodeId ? <MdOutlineRemove /> : <BsThreeDots />}
          </div>
        </button>
      ))}
    </div>
  );
};
export default BuildingSankeyChart;
