// import { useFetchBuildingView } from 'api/assets/buildingViewService';
import { type FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BuildingCard } from './components/BuildingCard';
import { BuildingOverallPerformance } from './components/BuildingOverallPerformance';
import { BuildingSankeyChart } from './components/BuildingSankeyChart';
import { BuildingNeedAttentionTable } from './tables/BuildingNeedAttentionTable';
import { MostImportantChangesTable } from './tables/MostImportantChangesTable';
import { CardContainer } from 'layout/Card';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { BuildingSankeyButton } from './components/BuildingSankeyButton';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import buildingViewMockData from './mock/buildingViewMock.json';

const BuildingViewPage: FC = () => {
  const { id } = useParams();

  // const { data, isFetching } = useFetchBuildingView(id);
  const data = buildingViewMockData;
  const isFetching = false;
  const [selectedSankeyOption, setSelectedSankeyOption] = useState(
    UtilityTypeEnum.ELECTRICITY as string
  );
  return (
    <div className="flex flex-col gap-4">
      {/* HEADER */}
      <div className="flex gap-4">
        <BuildingCard asset={data?.asset_impact} isFetching={isFetching} />
        <div className="w-3/12">
          <BuildingOverallPerformance
            building={data?.asset_impact}
            isFetching={isFetching}
          />
        </div>
      </div>
      {/* TABLES */}
      <div className="flex gap-4">
        {/* TOP CHANGES TABLE */}
        <div className="flex w-1/2 flex-col gap-4">
          <div className="flex w-full justify-between items-center my-2">
            <h3 className="font-bold uppercase">TOP 3 DETECTED CHANGES</h3>
            <Link
              to={`/app/operations/anomalies?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <MostImportantChangesTable buildingId={id} />
        </div>
        {/* NEED ATTENTION PROJECTS TABLE */}
        <div className="flex w-1/2 flex-col gap-4">
          <div className="flex justify-between items-center my-2">
            <h3 className="font-bold uppercase">Projects</h3>
            <Link
              to={`/app/management/projects?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <BuildingNeedAttentionTable />
        </div>
      </div>
      <CardContainer gap={6}>
        <div className="flex justify-between items-center">
          <h3 className="font-bold uppercase">ENERGY OVERVIEW 2024</h3>
          <div className="flex gap-4 items-center">
            <p>Showing</p>
            <SingleSelectInput
              options={[{ value: 1, name: 'Last year (2023)' }]}
              value={1}
              maxWidth="250px"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div>
          <BuildingSankeyButton
            id={UtilityTypeEnum.ELECTRICITY as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.ELECTRICITY}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.ELECTRICITY}
            value={422.0}
            percentage={12}
            unit={'kWh'}
          />
        </div>
        <BuildingSankeyChart id={id} />
      </CardContainer>

      {/* MEASURED VS EXPECTED */}
      {/* <div>
        <MeasuredVsExpectedChart />
      </div> */}
    </div>
  );
};
export default BuildingViewPage;
